import React, { useEffect, useState } from "react";

import { useNavigate, useLocation, useParams } from "react-router-dom";

import ReactGA from "react-ga4";

// Importanto componente
import { ServicesOptions } from "../../../../components/Services/ServicesOptions/ServicesOptions";
import { CompanyList } from "../../../../components/CompanyList/CompanyList";
import { Button, Subheader } from "../../../../components";
import { Loader } from "../../../../components/Loader/Loader";

import { useDispatch, useSelector } from "react-redux";

// Importando Slice
import { GetServicesByType } from "../../../../Slices/Service";
import { GetServiceTypeActive } from "../../../../Slices/ServiceType";
import { setHistoryLocation } from "../../../../Slices/HistoryLocation";

// Importando store
import type { AppDispatch } from "../../../../Store/Store";

import {
  PROVIDER,
  SERVICE,
  SERVICE_SELLER,
} from "../../../../Consts/BaseUrl";

// importando datos de pruebas
// import { CompanylistData } from "../../../../testdata/CompanyListData";
//

// imoprtando interfaces
import { ServiceInterface } from "../../../../interfaces/Service";

// importando estilos
import "./MainServicePage.css";
import { GetCompanyById } from "../../../../Services/Company.service";

import { HeadPage } from "../../../../components/HeadPage/HeadPage";
import { FilterByRelevance } from "../../../../Consts/FilterRelevance";
// import { ModalAnimation } from "../../../../components/Modal/ModalAnimation/ModalAnimation";
import { ModalProcessService } from "../../../../components/Modal/ModalProcessService/ModalProcessService";
import { BackArrow } from "../../../../icons/outline/BackArrow";
import { SearchBlack } from "../../../../icons/outline/SearchBlack";
import { SearchTextField } from "../../../../components/SearchTextField/SearchTextField";  
import { FilteHorizontal } from "../../../../icons/outline/FilterHorizontal";
import { ModalFilter } from "../../../../components/Modal/ModalFilter/ModalFilter";
import { FilterService } from "../../../../components/FilterService/FilterService";
import { FilterCategory, FilterCountry } from "../../../../Consts/FilterCategory";
import { LoaderShimmerService } from "../../../../components/LoaderShimmerService/LoaderShimmerService";

interface MainServicePageProps {}

export const MainServicePage: React.FC<MainServicePageProps> = () => {
  let navigation = useNavigate();
  const params = useParams();
  let location = useLocation();

  const dispatch = useDispatch<AppDispatch>();

  // get variables from redux
  const { serviceList } = useSelector((state: any) => state.service);
  const { serviceTypeList } = useSelector((state: any) => state.serviceType);
  const { userDetail } = useSelector((state: any) => state.user);

  const [typeList, setTypeList] = useState<string[]>([]);
  const [resultServiceList, setResulServiceList] = useState<
    ServiceInterface[]
  >([]);

  /** UseState for filter services and type-service */
  // const [serviceKeyboards, setServiceKeyboards] = useState<ServiceKeyboards[]>([]);
  // const [searchServiceKeyboards, setSearchServiceKeyboards] = useState<ServiceKeyboards[]>([]);
  // const [serviceLeaked, setServiceLeaked] = useState<ServiceInterface[]>([]);

  const [showBack, setShowBack] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [showQuotationButton, setShowQuotationButton] =
    useState<boolean>(false);

  const [serviceType, setServiceType] = useState('agenciamiento-de-aduana');

  const [showRequestQuote, setShowRequestQuote] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  // const [showModalAnimation, setShowModalAnimation] = useState<boolean>(false);

  const [filterTitle, setFilterTitle] = useState<string>("Todos");

  // metodo para filtrar los servicios por relevancia
  const handleFilterRelevance = (relevance: string) => {
    setFilterTitle(relevance);
    switch (relevance) {
        case FilterByRelevance[0]:
          orderByRatingServices();
          break;
        case FilterByRelevance[1]:
          orderByReviewServices();
          break;
        case FilterByRelevance[2]:
          orderByTimeServices();
          break;
        case FilterByRelevance[3]:
          setResulServiceList(serviceList);
          break;
        default:
          setResulServiceList(serviceList);
          break;
    }
  }

// filtrar los servicios por rating
  const orderByRatingServices = () => {
    if(resultServiceList.length>0) {
        setResulServiceList([...serviceList].sort((a, b) => { return b.rating - a.rating}));
    }
  }

  const orderByReviewServices = () => {
    if(resultServiceList.length>0) {
      setResulServiceList([...serviceList].sort((a, b) => { return b.numComments - a.numComments}));
    }
  }

  const orderByTimeServices = () => {
      if(resultServiceList.length>0) {
        setResulServiceList([...serviceList].sort((a, b) => { return Number(a.createdAt) - Number(b.createdAt)}));
      }
  }

  // obtiene serviciosKeyboard activos
//   const getServiceKeyword = async () => {

//     try {
//         const response: any = await GetAllServicesKeyboardsActive();
//         setServiceKeyboards(response);
//         setSearchServiceKeyboards(response);
//     } catch (error) {
//         console.log("🚀 ~ file: CompanyList.tsx:100 ~ getServiceKeyboards ~ error:", error)
//         setServiceKeyboards([]);
//     }
// }

  // metodo para abrir detalle del servicio
  const handleClickService = (
    serviceId: string | undefined,
    serviceType: string
  ) => {
    if (
      serviceId &&
      serviceId.length > 0 &&
      serviceType &&
      serviceType.length > 0
    ) {
      GA4_select_item(serviceList.find((s: any) => s.id === serviceId));
      navigation(`/servicios/${serviceType}/${serviceId}`);
    }
  };

  // metodo para obtener los servicios activos
  const getServices = async (type: string) => {
    if (!loading) {
      setLoading(true);
      dispatch(GetServicesByType(type))
        .unwrap()
        .then((response) => {
          setResulServiceList(response);
          GA4_view_item_list(type, response)
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const getTypeServices = async () => {
    if (!loading) {
      setLoading(true);
      dispatch(GetServiceTypeActive())
        .unwrap()
        .then((response) => {
          setTypeList(response.map((r: any) => r.name));
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  //Buscar por titulo de las ordenes
  const searchOrderService = (text: string) => {
    if (text.length > 0) {
      setResulServiceList(
        serviceList.filter(
          (rqdl: any) =>
            rqdl.companyName.toUpperCase().includes(text.toUpperCase()) ||
            rqdl.title.toUpperCase().includes(text.toUpperCase())
        )
      );
    } else {
      setResulServiceList(serviceList);
    }
    /*text.length>0?
        setResulDraft(requestQuoteDraftList.filter((rqdl:any) => (rqdl.title.toUpperCase()).includes(text.toUpperCase()))):
        setResulDraft(requestQuoteDraftList)

        console.log('buscar', text)*/
  };

  // const searchFilter = (text: string) => {    
  //   setSearchServiceKeyboards(
  //     serviceKeyboards.filter(
  //       (sk) => sk.name.toUpperCase().includes(text.toUpperCase())
  //     )
  //   )
  // }

  const handleWspClick = async (companyId: string) => {
    if (!loading) {
      try {
        setLoading(true);
        const company: any = await GetCompanyById(companyId);
        setLoading(false);
        window.open(
          company.whatsapp && company.whatsapp.length > 0
            ? "https://api.whatsapp.com/send/?phone=" +
                company.whatsapp +
                "&text=Hola! " +
                company.name +
                "! Quiero hablar con un asesor, vengo de Arkabia&type=phone_number&app_absent=0"
            : "",
          "_blank"
        );
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleTypeService = async (serviceTypeList:string) => {
    // setServiceType(serviceTypeList);
    handleFilterRelevance("Todos");
    navigation(`/${SERVICE}/${serviceTypeList}`)
  

  }

  // const handleFilterKeyboards = (keyboardItem: string) => {
  //   if(keyboardItem.length === 0) {
  //     setResulServiceList(serviceList);
  //       return;
  //   }
  //   setResulServiceList(serviceList.filter((s:any) => s.keywords?.includes(keyboardItem)));
  // }

  // handle search
  
  const handleSearch = () => {
    setActiveSearch(true);
  }

  // show modal for first time
  // const getHelpModalStatus = () => {
  //   const activeHelpModal = GetLocalStorage(HELP_MODAL_SERVICE);
  //   // setShowModalAnimation(!activeHelpModal);
  //   SetLocalStorage(HELP_MODAL_SERVICE, true);
  // }

  const handleModalRequestQuote = () => {
    let type = params.serviceType ? params.serviceType : 'agenciamiento-de-aduana';
    setServiceType(type);
    setShowRequestQuote(true);
  }

  const handleModalFilter = () => {
    setShowFilter(true);
  }

  const handleFilterBy = (filter: string) => {
    // let filter_split = filter.split(" ");
    if(filter === "Todos") {
      setResulServiceList(serviceList);
    } else {
      if(serviceType !== "busqueda-de-proveedor") {
        setResulServiceList(serviceList.filter((s: ServiceInterface) => s.keywords?.includes(filter)));
      }
    } 
  }

  // Method to send data to GA4
  // Method to send view_item_list to GA4
  const GA4_view_item_list = (serviceType: string, services: ServiceInterface[]) => {
    // Send view_item_list to GA4
    ReactGA.gtag("event", "view_item_list", {
      item_list_id: "services_"+serviceType,
          item_list_name: "Services "+services[0].serviceTypeLabel,
          items: services.map((s) => ({
            item_id: s.id,
            item_name: s.title,
            item_brand: s.companyName,
            item_category: s.serviceTypeLabel,
            item_list_id: "services_"+serviceType,
            item_list_name: "Services "+s.serviceTypeLabel,
            quantity: 1
          }))
    });
  }

  // method to send the select_item to GA4
  const GA4_select_item = (service: ServiceInterface) => {
    ReactGA.gtag("event", "select_item", {
      item_list_id: "services_"+serviceType,
      item_list_name: "Services "+service.serviceTypeLabel,
      items: [{
        item_id: service.id,
        item_name: service.title,
        item_brand: service.companyName,
        item_category: service.serviceTypeLabel,
        item_list_id: "services_"+serviceType,
        item_list_name: "Services "+service.serviceTypeLabel,
        quantity: 1
      }]
    });
  }

  // useFect block
  // mostrar boton de regresar
  useEffect(() => {
    const searchParms = new URLSearchParams(location.search);
    if (searchParms.has("back") && searchParms.has("provider")) {
      const back = searchParms.get("back") === "true";
      const isProvider = searchParms.get("provider") === "true";
      setShowBack(back && isProvider);
    }
  }, [location.search]);

  //obtener servicios
  useEffect(() => {
    // getServiceKeyword();
    getTypeServices();
    // getHelpModalStatus();
    dispatch(setHistoryLocation(location.pathname));
  }, []);

  // carga quotation con datos del usuario
  useEffect(() => {
    if (userDetail && userDetail.id) {
      setShowQuotationButton(userDetail.userType.includes("user"));
    } else {
      setShowQuotationButton(true);
    }
  }, [userDetail]);

  useEffect(()=> {
    let type = params.serviceType ? params.serviceType : 'agenciamiento-de-aduana';
    setServiceType(type);
    getServices(type);
}, [params.serviceType, serviceTypeList]);

// useEffect(()=>{
  //   window.document.body.style.overflow =  showModalAnimation ? 'hidden' : 'unset';
  // },[showModalAnimation]);

  return (
    <div className="mainService">
      <div className="mainService-container">
        <div className="mainService-header">
          {showBack && ( 
            <div className="mainService-provider">
              <HeadPage
                title={`${userDetail && userDetail.name ? "¡Hola "+userDetail.name+"!," : ""}`}
                subTitle={serviceTypeList.length> 0 ? serviceTypeList.find((s: any) => s.id === serviceType).description : ""}
              />
              <div className="order__nav">
                {/** Tab de ordenes y calendario */}
                <Subheader
                  content="Terceros"
                  active={true}
                  handleClick={() => {}}
                />

                <Subheader
                  content="Mis servicios"
                  active={false}
                  handleClick={() =>
                    navigation(`/${PROVIDER}/${SERVICE_SELLER}`)
                  }
                />
              </div>
              <div className="mainService-services">
            {/* end mobile section */}
            
          </div>
            </div>
          )}
            
          {!showBack &&
            <div className="mainService-provider">
              {serviceTypeList && (
                <ServicesOptions
                  servicesOptions={serviceTypeList}
                  ServicesOptionsDataDropdown={typeList}
                  setTextSearch={(e: any) => searchOrderService(e)}
                  serviceTypeActive={serviceType}
                  onClick={handleTypeService}
                  title='Buscar agencia'
                  userName={`${userDetail && userDetail.name ? "¡Hola "+userDetail.name+"!,"  : ""}`}
                />
              )}
              <HeadPage
                title={''}
                subTitle={serviceTypeList.length> 0 ? serviceTypeList.find((s: any) => s.id === serviceType).description : ""}
              />
            </div>
          }
        </div>

        <div className={resultServiceList.length>3 ?"mainService-body" : "mainService-body-min-3"}>
          <div className="mainService-company">
            <div className="mainService-main-content-filter"> 
              {/* <FilterSelectorExtended
                relevanceFilter={FilterByRelevance}
                handleSelectRelevanceItem={handleFilterRelevance}
                handleKeyboardSelected={handleFilterKeyboards}
                category= {serviceTypeList}
                handleCategorySelected={handleTypeService} 
                categoryId={serviceType}
                titleFilterOne = {filterTitle}
                titleFilterTwo = "Filtrar"
              />    */}
              <div className="mainService-main-content-filters">
                <FilterService
                  title="Ordenar por"
                  titleDropdown="Todos"
                  filterItems= {FilterByRelevance}
                  handleItemSelected={handleFilterRelevance}
                />
                {serviceType && serviceType === "agenciamiento-de-aduana" && <FilterService
                  title={""}
                  titleDropdown={"Categoría"}
                  filterItems= {FilterCategory }
                  handleItemSelected={handleFilterBy}
                />}
                {serviceType && serviceType === "courier" && <FilterService
                  title={"Compra de" }
                  titleDropdown={ "Origen" }
                  filterItems= {FilterCountry}
                  handleItemSelected={handleFilterBy}
                />}
              </div>
              <div className={!activeSearch ? "mainservice-filters" : "mainservice-filters-expand"}>
                {/*<FilterSelectorMiniature preferences={[]} sortBy={[]} />
                */}
                
                {activeSearch && 
                    <BackArrow 
                        className="chat-search-content-icon-back" 
                        onClick={()=>setActiveSearch(false)}
                    />
                }
                {!activeSearch ? 
                    <SearchBlack className="chat-search-icon" onClick={handleSearch}/> :
                    <SearchTextField 
                        title={window.innerWidth <= 481 ? "Buscar": 'Buscar'}
                        setTextSearch={(e: any) => searchOrderService(e)}
                        darkIcon 
                    />
                }
                {!activeSearch ? 
                  <div className="mainservice-filter-icon" onClick={handleModalFilter}>
                    Filtros
                    <FilteHorizontal/>
                  </div> : 
                  null
                }
            </div>   

      
              {showQuotationButton && !activeSearch ? <div className="companyList-requestQuoteButton">
                  <Button 
                    content={serviceType === "agenciamiento-de-aduana" || serviceType === "courier" ? 'Cotización múltiple' : "Comprar" }
                    color="black-75" size="normal" 
                    onClick={handleModalRequestQuote} />
              </div> :null}
            </div>

            {!loading  ? (
              resultServiceList && resultServiceList.length > 0 ? (
              <CompanyList
                serviceData={resultServiceList}
                chooseAgency={false}
                requestQuotes={[]}
                onClickAddQuoteAgency={(e) => {
                  // console.log(e);
                }}
                onClickService={handleClickService}
                onClickWsp={handleWspClick}
                showButtonWsp
              />) :(
                <div className="paragraph">No contamos el servicio disponible</div>
              )
            ) : (
              <div className="mainService-loader">
                {Array.from({ length: window.innerWidth > 899 ? 9 : 4 }).map((_, index) => (
                  <LoaderShimmerService key={index} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      
      {/* <div className="modal-quote-desktop">
       {showRequestQuote && <ModalQuote
          requestQuote={requestQuote}
          setRequestQuote={setRequestQuote}
          show={showRequestQuote}
          setShow={setShowRequestQuote}
          fromPage="mainService"
        />}
      </div> */}

      <ModalProcessService
        fromPage="mainService"
        serviceTypeId={serviceType}
        show={showRequestQuote}
        setShow={setShowRequestQuote}
      />
      
        {/* <ModalProcessNew
          fromPage="mainService"
          serviceTypeId={serviceType}
          show={showRequestQuote}
          setShow={setShowRequestQuote}
        /> */}

      <ModalFilter
        show={showFilter}
        setShow={setShowFilter}
        typeService={serviceType}
        handleFilterRelevance={handleFilterRelevance}
        FilterByRelevance={FilterByRelevance}
        FilterCategory={FilterCategory}
        handleFilter={handleFilterBy}
      />

      

      {/* <ModalAnimation
        show={showModalAnimation}
        setShow={setShowModalAnimation}
        animation={[
          { 
            path: '/animations/Desktop/OrderFilter/data_ordenar_y_filtrar_1024.json',
            title: 'Ordenar y filtrar',
            image: STORAGE_ASSETS+'/images/png/orderfilter.png',
            description: 'Encuentra fácil a la agencia de aduana con más experiencia en tu industria.' 
          },
          { 
            path: '/animations/Desktop/ProfileMessage/data_perfil_y_mensaje_1024.json',
            title: 'Enviar mensaje',
            image: STORAGE_ASSETS+'/images/png/message.png',
            description: 'Conversa directamente con expertos sobre tu producto.' 
          },
          { 
            path: '/animations/Desktop/RequestQuote/data_solicitar_cotizacion_1024.json',
            title: 'Solicitar cotización',
            image: STORAGE_ASSETS+'/images/png/requestquote.png',
            description: 'Envíale tus requerimientos a distintas agencias y compara precios.' 
          }
        ]}
      /> */}
    </div>
  );
};
